
import {NavLink, Container, Row, Col, Stack, Image, Nav, Navbar} from "react-bootstrap"
import cmpLog from "../img/migrationLog.png"
import menuData from "./Navbars"
import "./Footerm.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"

function Footerm () {
    return(<Container fluid className="bg-dark ft-0">

            <Row className="text-light p-5 ft-4">
                <Col xs={12} md={4} className="justify-content-center align-items-center ft-1">
                    <h3>Immigration<span>Reviews</span></h3>
                    <small>If you're happy with a service or product you've recently bought or ordered, share your experience with others to raise a company's credibility in the public eye. In case you have something to complain about, you're welcome to leave your feedback, too. Your point of view will help a company to eradicate the existing problems and improve client experience.</small>
                    <div className="footer-icons">
                        <i class="bi bi-facebook"/>
                        <i class="bi bi-twitter"/>
                        <i class="bi bi-instagram"/>
                        <i class="bi bi-linkedin"/>
                    </div>
                </Col>
                <Col xs={12} md={3} className="justify-content-center align-items-center">
                    <Nav className="flex-column">
                        <h4>Userful Links</h4>
                        <NavLink href="#" className="text-block">Home</NavLink>
                        <NavLink href="#" className="text-block">About</NavLink>
                        <NavLink href="/categories" className="text-block">Categories</NavLink>
                        <NavLink href="#" className="text-block">We're hiring!</NavLink>
                    </Nav>
                </Col>
                <Col xs={12} md={3} className="justify-content-right">
                    <Nav className="flex-column fs-6 ft-3">
                        <h4>Contact Info</h4>
                        <NavLink href="mailto:enquries.info@immigrationreviews.com" className="text-block align-items-center">
                            <small><i class="me-2 bi bi-envelope-at-fill"/>enquries.info@immigrationreviews.com</small>
                        </NavLink>
                        <NavLink href="#" className="text-block"><i class="me-2 bi bi-geo-alt-fill"/>Abi Duabi, UAE</NavLink>
                        <NavLink href="tel:+912388029307" className="text-block"><i class="me-2 bi bi-telephone-fill"/>+91 2388029307</NavLink>
                    </Nav>
                </Col>
                <Col xs={12} md={2} className="justify-content-center align-items-center">
                    <Nav className="flex-column fs-6">
                        <h4>Business State</h4>
                        <NavLink href="#" className="text-block">Consumer Business</NavLink>
                        <NavLink href="#" className="text-block">Plans & Pricings</NavLink>
                    </Nav>
                </Col>
            </Row>
        </Container>)
}
export default Footerm;