import {useEffect, useState} from "react"
import { Card, Container,Image, Col, Row, Button } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import HomePage from "./HomePage";

function ProfilePage(props){
    const [selfPosts, setSelfPosts] = useState([]);
    // const [userName, setUserName] = useState(null);    

    useEffect(()=>{
        getProfileInit()
    },[])

    async function getProfileInit(){
        const responce = await fetch("http://208.109.231.225:9812/api/v1/health_check")
        const jsonData = await responce.json();
        if (responce.status === 200){
            console.log(jsonData)
            
            const getReviewRep = await fetch(`http://208.109.231.225:9812/api/v1/get_review?code=UsrId&value=${jsonData["msg"][1]}`, {method:"GET" })
            const reviewJson = await getReviewRep.json()
            if (await getReviewRep.status === 200){
                console.log(reviewJson)
                setSelfPosts(reviewJson)
            }
        }
        
    }

    const ProfileLetter = ({name}) => {
        return(<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:50, height:50, borderRadius:"50%",backgroundColor:"rgb(70, 200, 36)"}}>
                <span style={{color:"white", fontSize:"20px", fontWeight:"bold"}}>{name.charAt(0).toUpperCase()}</span>
            </div>
        </div>)
    }

    function OverViewRate1(inActive, sizess){
        return([...Array(5)].map((st, index) => {
            return(
                <div role="button" className=" bi bi-star-fill me-2 display-7"
                    style={index <= inActive.inActive ?{color: "#ffc107" }: {color:"#e4e5e9"}}
                />
            )
        }))
    }

    return(<div style={{marginTop:120, marginBottom: 80}}>

        {console.log(props.aliveSt)}
        { props.aliveSt === false ? <>
            <div style={{bottom: 80}}>
                <HomePage />
            </div>
        </> : <>
            <Container>
                <Row>
                    <Col md={3} sm={12}/>
                    <Col md={6} sm={12}>
                        <h3>Welcome {props.userName}</h3>
                        <br/><hr/>
                        <h4>Your Posts : {selfPosts.length}</h4>
                        {
                            selfPosts && selfPosts.map((item) => (
                                <Card className="me-4 mb-3" style={{width:"35rem", height:"17rem"}}>
                                    <Card.Body className="">
                                        <Card.Header>
                                            <div className="d-flex">
                                                <ProfileLetter name={item.Name}/>
                                                <h4 className="mt-3 ms-3">{item.Name}</h4>
                                            </div>
                                            <div className="d-flex"><OverViewRate1 inActive={3}/></div>
                                            <Card.Subtitle  className="text-muted mt-2">
                                                reviewd <a style={{color:"#000000"}} href={item.CmpLnk}>{item.CmpName}</a><br/><small>{Date(item.Posted)}</small>
                                            </Card.Subtitle>
                                        </Card.Header>

                                        <div className="mt-3 mb-5" style={{overflow:"hidden", textOverflow:"ellipsis",display:"-webkit-box", "webkitBoxOrient":"vertical", WebkitLineClamp:4}}>
                                            <h6>{item.Title}</h6>
                                            {item.Body}
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))
                        }
                    </Col>
                    <Col md={3} sm={12}/>

                </Row>
            </Container>
        </>}
    </div>)
}

export default ProfilePage;