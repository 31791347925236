
import { useState, useEffect } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

const OverViewRate1 = ({inActive, sizess}) => {
    return([...Array(5)].map((st, index) => {
        return(
            <div role="button" className=" bi bi-star-fill me-2"
                style={index <= inActive ? {color: "#ffc107" }: {color:"#e4e5e9"}}
            />
        )
    }))
}

function CategoriesListTable(){
    const [listOfCateItem, setListOfCateItem] = useState([]);
    const { cateName, cateCode } = useParams();

    useEffect(()=>{
        GetCateListItem();
    }, [])

    async function GetCateListItem(){
        let responce = await fetch(`http://208.109.231.225:9812/api/v1/category?type=${cateCode}`)
        let jsonDate = await responce.json()
        if (responce.status === 200) {
            setListOfCateItem(jsonDate);
        }
    }

    return(<>
        <Container style={{marginTop:120, marginBottom: 80}} className="text-center justify-content-center">
            <Row>
                <Col md={3}/>
                <Col md={6}>
                    <h3>Best "{cateName}" category companies</h3>
                    <p>Discover the best companies with Trustburn </p>
                </Col>
                <Col md={3}/>
            </Row>
        </Container>

        <Container className="mb-4">
            {
                listOfCateItem.length > 0 && 
                listOfCateItem.map((item)=>(
                    <Card className="w-75 mt-3 mb-3" style={{margin: "0 auto", float:"none"}}>
                        <Row className="d-flex">
                            <Col xs={12} md={4} className="p-4">
                                <Card.Img style={{width : "13rem", height: "13rem"}} src={`http://208.109.231.225:9812${item.img}`}/>
                            </Col>
                            <Col xs={12} md={7}>
                                <Card.Body >
                                    <Card.Link as={Link} to={`/review/${item.code}/${item.name}`} key={item.name} style={{color:"black"}}>
                                        <h6>{item.name}</h6>
                                    </Card.Link>
                                    <div>Reviews {item.rvcount}</div>
                                    <div className="d-flex">
                                        <OverViewRate1 inActive={item.rvrate-1}/>
                                        <div>{item.rvrate}</div>
                                    </div>
                                    <hr/>
                                    <p style={{ overflow:"hidden", display:"-webkit-box", WebkitLineClamp:3, WebkitBoxOrient:"vertical", lineHeight:"1em"}}>
                                        <small>{item.note}</small>
                                    </p>
                                </Card.Body>
                            </Col>            
                        </Row>
                    </Card>
                ))
            }
        </Container>
    </>)
}

export default CategoriesListTable;