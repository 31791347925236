import {Navbar, Container,Nav, Button, Dropdown, NavDropdown} from "react-bootstrap"
import {BrowserRouter, Route, Link, Routes, Outlet} from "react-router-dom"
import { useState, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Navbars.css"

import Categories from "./Categories"
import HomePage from "./HomePage"
import CategoriesListTable from "./CategoriesListTable"
import ReviewPage from "./ReviewPage"
import SignupPage from "./SignupPage"
import ProfilePage from "./ProfilePage"

const menuData = [
    {
        path: "/", 
        name: "Home"
    },
    {
        path: "/categories", 
        name: "Categories"
    },
    // {
    //     path: "/about", 
    //     name: "About"
    // },
    // {
    //     path: "/contact", 
    //     name: "Contact"
    // },
]

const ProfileLetter = ({name}) => {
    return(<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:50, height:50, borderRadius:"50%",backgroundColor:"blue"}}>
            <span style={{color:"white", fontSize:"20px", fontWeight:"bold"}}>{name.charAt(0).toUpperCase()}</span>
        </div>
    </div>)
}

function Navbars() {

    const [expanded, setExpanded] = useState(false);
    const [signModalSt, setSignModalSt] = useState(false);
    const [signIconSt, setSignIconSt] = useState(false)
    const [usernamepic, setUsernamepic] = useState("V");
    const [userId, setUserId] = useState(null);

    useEffect(()=> {
        getProfileInit()
    }, [])

    async function getProfileInit(){
        const responce = await fetch("http://208.109.231.225:9812/api/v1/health_check")
        const jsonData = await responce.json();
        if (responce.status === 200){
            setUsernamepic(jsonData["msg"][0]);
            setUserId(jsonData["msg"][1])
            setSignIconSt(true);
            setSignModalSt(false)
            
        }
        
    }

    const handlerSignup = () => {
        setSignModalSt(true)
    }

    async function haandleLogout(){
        const resps = await fetch("http://208.109.231.225:9812/api/v1/logout")
        if (resps.status === 200){
            setSignIconSt(false)
            setSignModalSt(false)
        }
    }

    return(<>
    {
        signModalSt && <SignupPage  show={signModalSt} onHide={() => setSignModalSt(false)} 
            onHideInner={setSignModalSt} aliveSt={setSignIconSt}
            usernamepic={setUsernamepic} setUserIdC={setUserId}
        />
    }
            <BrowserRouter>
                <Navbar collapseOnSelect expanded={expanded} expand="lg"  fixed="top" className="navbar-dark bg-dark">
                    <Container >
                        <Navbar.Brand href="/">
                            <span style={{color:"#F7F7F7"}}>Migration</span>
                            <span style={{color:"#FA4B04"}}>Reviews</span>
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={() => {setExpanded(!expanded)}} aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                {
                                    menuData.map((item) => (
                                        <Nav.Link onClick={() => {setExpanded(false)}} as={Link} to={item.path} key={item.name}>
                                            <div className="list_item">{item.name}</div>
                                        </Nav.Link>
                                    ))
                                }
                            </Nav>
                            <Nav className="ms-auto">
                                {
                                    signIconSt === false ? <>
                                        <Button onClick={() => handlerSignup()} className="btn btn-succss">Login In</Button>
                                    </> : <>
                                        <NavDropdown title={<ProfileLetter name={usernamepic}/>}>
                                            <NavDropdown.Item as={Link} to={"/profile"} key={"Profile"}>Profile</NavDropdown.Item>
                                            <NavDropdown.Item onClick={()=>haandleLogout()}>Sign Out</NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Container className="mt-4">
                    <Routes>
                        <Route path="/" element={<Outlet/>}>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/categories" element={<Categories/>}/>
                            <Route path="/profile" element={<ProfilePage aliveSt={signIconSt} userName={usernamepic}/>}/>
                            <Route path="/categories/:cateCode/:cateName" element={<CategoriesListTable/>}/>
                            <Route path="/review/:cateCode/:cmpName" element={<ReviewPage aliveSt={signIconSt} userId={userId} userName={usernamepic} onSingup={setSignModalSt}/>}/>
                        </Route>
                    </Routes>
                </Container>
            </BrowserRouter>
    </>)
}

export default Navbars;