import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"

import Navbars from "./component/Navbars"
import Footerm from "./component/Footerm"
// import ReviewPage from "./component/ReviewPage"
// import HomePage from './component/HomePage';


function App() {
  return (
    <>
      <Navbars/>
      <Footerm/>
    </>
  );
}

export default App;
